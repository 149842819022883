
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  Camera,
  VideoWallItem,
  VideoWallItemType,
} from "@evercam/shared/types"
import VideoWallGallery from "@/components/videoWalls/VideoWallGallery"
import { useVideoWallStore } from "@evercam/dashboard/stores/videoWall"
import { mapStores } from "pinia"
import { useProjectStore } from "@evercam/dashboard/stores/project"

export default {
  name: "ProjectVideoWall",
  meta: {
    pageId: AnalyticsEventPageId.VideoWall,
  },
  components: { VideoWallGallery },
  async asyncData({ route }) {
    const { hideSettings } = route.query

    return { hideSettings: hideSettings === "true" }
  },
  async fetch() {
    const videoWallStore = useVideoWallStore()
    await videoWallStore.fetchVideoWallConfiguration()
  },
  head() {
    return {
      title: "Evercam | Video Wall",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useVideoWallStore, useProjectStore),
    items(): VideoWallItem<Camera>[] {
      return this.projectStore.selectedProjectCameras.map((c) => ({
        value: c,
        type: VideoWallItemType.LiveView,
      }))
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    if (!this.$permissions.project.has.go()) {
      this.$fullscreen.toggle(this.$globalRefs.mainContainer)
    }
  },
}
